import React from 'react';
import './SobreMi.css';
import './RSobreMi.css';

const SobreMi = () => {
  return (
    <section className="sobre-mi-section">

      <header className="sobre-mi-header">
        <figure className="sobre-mi-figure">
          <img
            src="https://img.icons8.com/?size=100&id=99268&format=png&color=f2f2f2"
            alt="Foto de Esteban Jara"
            className="sobre-mi-image"
          />
        </figure>
        <h1 className="sobre-mi-title">Sobre Mi</h1>
      </header>
      <div className="sobre-mi-content">
        <article className="sobre-mi-article">
          <p>
            Soy <strong>Analista Programador Computacional</strong> y <strong>Técnico en Radiodiagnóstico y Radioterapia</strong>,
            actualmente cursando <strong>Ingeniería en Informática.</strong>
            Tengo experiencia académica en diversos lenguajes de programación,
            incluyendo Python, Java y JavaScript, así como en tecnologías como React, Node.js y bases de datos relacionales y no relacionales (SQL y MongoDB). Me apasiona el desarrollo frontend y la optimización de procesos de negocio mediante soluciones tecnológicas eficientes y fáciles de usar.
          </p>
        </article>
      </div>
    </section>
  );
}

export default SobreMi;
