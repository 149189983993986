import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Proyectos.css';

const proyectos = [
  {
    id: 1,
    nombre: 'Proyecto A',
    descripcion: 'Este es el primer proyecto.',
    fechaCreacion: '2024-10-01',
    estado: 'En progreso',
    imagen: 'https://via.placeholder.com/100'
  },
  {
    id: 2,
    nombre: 'Proyecto B',
    descripcion: 'Este es el segundo proyecto.',
    fechaCreacion: '2024-09-15',
    estado: 'Completado',
    imagen: 'https://via.placeholder.com/100'
  },
  {
    id: 3,
    nombre: 'Proyecto C',
    descripcion: 'Este es el tercer proyecto.',
    fechaCreacion: '2024-08-20',
    estado: 'Pendiente',
    imagen: 'https://via.placeholder.com/100'
  }
];

const Proyectos = () => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    // Redirige al componente de detalle del proyecto
    navigate(`/proyecto/${id}`);
  };

  return (
    <div className="proyectos-lista">
      <h2 className="proyectos-titulo">Lista de Proyectos</h2>
      <ul className="proyectos-ul">
        {proyectos.map((proyecto) => (
          <li 
            key={proyecto.id} 
            className="proyectos-item"
            onClick={() => handleClick(proyecto.id)}
          >
            <img src={proyecto.imagen} alt={proyecto.nombre} className="proyectos-imagen" />
            <h3 className="proyectos-nombre">{proyecto.nombre}</h3>
            <p className="proyectos-descripcion">{proyecto.descripcion}</p>
            <p className="proyectos-fecha"><strong>Fecha de Creación:</strong> {proyecto.fechaCreacion}</p>
            <p className="proyectos-estado"><strong>Estado:</strong> {proyecto.estado}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Proyectos;
