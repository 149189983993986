import React from 'react';
import './Titulos.css';


// Datos simulados de títulos (puedes reemplazarlos con una fuente de datos real)
const titulosData = [
  {
    id: 1,
    descTitulo: 'Analista Programador Computacional',
    annoTitulo: 'Agosto 2024',
    institucion: 'DuocUC',
    estado: 'Titulado'
  },
  {
    id: 2,
    descTitulo: 'Ingeniero en Informática',
    annoTitulo: '2025',
    institucion: 'DuocUC',
    estado: 'Cursando'
  },
  {
    id: 3,
    descTitulo: 'Técnico de Radiodiagnóstico y Radioterapia',
    annoTitulo: 'Julio 2017',
    institucion: 'DuocUC',
    estado: 'Titulado'
  }
];

// Componente de lista de títulos en formato tarjeta
const Titulos = () => {
  return (
    <div className="titulos-list-container">
      {/* Encabezado de la lista */}
      <h2 className="titulos-list-title">Antecedentes Académicos</h2>

      {/* Tarjetas de títulos */}
      <div className="titulos-card-container">
        {titulosData.map((titulo) => (
          <div className="titulos-card" key={titulo.id}>
            <div className="titulos-icon">
              <img
                src="https://img.icons8.com/?size=100&id=11216&format=png&color=27646B"
                alt="Icono"
                className="icon-image"
              />
            </div>
            <div className="titulos-card-content">
              <h3 className="titulos-desc">{titulo.descTitulo}</h3>
              <p className="titulos-anno">{titulo.annoTitulo}</p>
              <p className="titulos-institucion">{titulo.institucion}</p>
              <p className={`titulos-estado ${titulo.estado === 'Titulado' ? 'estado-titulado' : 'estado-cursando'}`}>
                {titulo.estado}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Titulos;
