import React from 'react'
import './Header.css'
import './RHeader.css'

const Header = () => {
  return (
    <div className='header-content'>

      <div className="logo-header">
        <img src="/img/tebidev-logo.png" alt="logo-tebidev" />
      </div>

      <div className="text-header">
        <h2>
         tebidev.com
        </h2>
        <h3>
          Portafolio de desarrollo y creatividad digital
        </h3>
      </div>

    </div>
  )
}

export default Header
