import React, { useState } from 'react';
import './Contacto.css'; // Asegúrate de que este archivo contenga tus estilos CSS

const Contacto = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    mensaje: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí podrías manejar el envío del formulario
    alert(`Formulario enviado: \nNombre: ${formData.nombre}\nEmail: ${formData.email}\nMensaje: ${formData.mensaje}`);
  };

  return (

    <div className="contact-section">
      <div className="contact-card">
        <div className="contact-card-header">
          <h3 className="contact-card-title">Esteban Jara González</h3>
        </div>
        <div className="contact-card-body">
          <p className="contact-info"><strong>Fecha de nacimiento:</strong> 7 de febrero de 1995</p>
          <p className="contact-info"><strong>Nacionalidad:</strong> Chilena</p>
          <p className="contact-info"><strong>Celular:</strong> +56 9 8434 0662</p>
          <p className="contact-info"><strong>Email:</strong> tebinjara@gmail.com</p>
        </div>
      </div>

      <div className="contact-card-form" style={{ '--delay': 2 }}>
        <div className="contact-card-content">
          <h3>Contáctame</h3>
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="contact-form-group">
              <label htmlFor="nombre">Nombre</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="contact-form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="contact-form-group">
              <label htmlFor="mensaje">Mensaje</label>
              <textarea
                id="mensaje"
                name="mensaje"
                value={formData.mensaje}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit" className="contact-submit">Enviar</button>
          </form>
        </div>
      </div>

    </div>


  );
};

export default Contacto;
