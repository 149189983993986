import React from 'react'
import './PersonalData.css'
import './RPersonalData.css'

const PersonalData = () => {
    return (
        <div className='personal-data-content'>
            <div className='personal-data-name-container'>
                <h1>ESTEBAN JARA GONZÁLEZ</h1>
            </div>

            <div className='personal-data-image-container'>
                <img src="/img/profile-image.png" alt="profile-front" />
            </div>
            
            <table className="personal-data-table">
                <thead className="personal-data-header">
                    <tr>
                        <th className="personal-data-header-title" colspan="2">Datos Personales</th>
                    </tr>
                </thead>
                <tbody className="personal-data-body">
                    <tr className="personal-data-row">
                        <td className="personal-data-label">Fecha de nacimiento:</td>
                        <td className="personal-data-value">7 de febrero de 1995</td>
                    </tr>
                    <tr className="personal-data-row">
                        <td className="personal-data-label">Nacionalidad:</td>
                        <td className="personal-data-value">Chilena</td>
                    </tr>
                    <tr className="personal-data-row">
                        <td className="personal-data-label">Estado civil:</td>
                        <td className="personal-data-value">Soltero</td>
                    </tr>
                    <tr className="personal-data-row">
                        <td className="personal-data-label">Comuna:</td>
                        <td className="personal-data-value">Quilpué</td>
                    </tr>
                    <tr className="personal-data-row">
                        <td className="personal-data-label">Celular:</td>
                        <td className="personal-data-value">+56 9 8434 0662</td>
                    </tr>
                    <tr className="personal-data-row">
                        <td className="personal-data-label">Correo electrónico:</td>
                        <td className="personal-data-value">tebinjara@gmail.com</td>
                    </tr>
                    <tr className="personal-data-row">
                        <td className="personal-data-label">GitHub:</td>
                        <td className="personal-data-value"><a href="https://github.com/TebinJara" target="_blank">github.com/TebinJara</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default PersonalData
