import axios from 'axios';

// URL base de tu API backend usando la variable de entorno
const API_URL = "https://www.tebidev.com/api";
console.log('API_URL:', API_URL); // Agrega esto para verificar si la URL está definida

// Función para obtener lenguajes de programación
const getLanguages = async () => {
  try {
    const response = await axios.get(`${API_URL}/languages`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los lenguajes de programación:', error);
    throw error;
  }
};


// Función para obtener las bases de datos
const getDatabases = async () => {
  try {
    const response = await axios.get(`${API_URL}/databases`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener las bases de datos:', error);
    throw error;
  }
};

// Función para obtener frameworks y librerías
const getFrameworks = async () => {
  try {
    const response = await axios.get(`${API_URL}/frameworks`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener los frameworks y librerías:', error);
    throw error;
  }
};

// Función para obtener herramientas
const getTools = async () => {
  try {
    const response = await axios.get(`${API_URL}/tools`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener las herramientas:', error);
    throw error;
  }
};

// Exportar todas las funciones como un objeto para facilitar la importación
export default {
  getLanguages,
  getDatabases,
  getFrameworks,
  getTools
};
