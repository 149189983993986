import React, { useState } from 'react';
import './Inicio.css';
import './RInicio.css';
import LanguageList from './lenguajes/LanguageList';
import PersonalData from './inicio/personalData/PersonalData';
import SobreMi from './inicio/sobreMi/SobreMi';
import Titulos from './inicio/titulos/Titulos';

const Inicio = () => {



    return (
        <article className='main-content'>
            <section className="main-1" >
                <PersonalData />
            </section>
            <section className="main-2" >
                <SobreMi />
                <Titulos />
            </section>

        </article>
    );
}

export default Inicio;
