import React, { useState } from "react";
import "./LayoutTebidev.css";
import "./RLayoutTebidev.css";
import Header from "./header/Header";
import Nav from "./nav/Nav";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contacto from "./main/contacto/Contacto";
import LanguageList from "./main/lenguajes/LanguageList";
import Proyectos from "./main/proyectos/Proyectos";
import Inicio from "./main/Inicio";

// #region Componente principal LayoutTebidev
const LayoutTebidev = () => {
  // #region Estados
  // Estado que controla la visibilidad del menú lateral (Nav)
  const [isNavVisible, setIsNavVisible] = useState(true);
  // #endregion

  // #region Funciones
  // Función que alterna la visibilidad del menú lateral al hacer clic en el botón
  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };
  // #endregion

  return (
    <Router>
      {/* Contenedor principal que envuelve todo el layout */}
      <div className="layout-tebidev">
        
        {/* Encabezado del sitio web */}
        <header className="header">
          {/* Componente del encabezado */}
          <Header />

          {/* Botón para mostrar/ocultar el menú lateral, solo visible en pantallas pequeñas */}
          <button className="toggle-nav-button" onClick={toggleNav}>
            &#9776;
          </button>
        </header>

        {/* Menú, controlado por el estado isNavVisible */}
        <nav className={`nav ${isNavVisible ? "visible" : "hidden"}`}>
          <Nav />
        </nav>

        {/* Contenido principal de la página */}
        <main className="main">
          <Routes>
            {/* Ruta para la página principal */}
            <Route path="/" element={<Inicio/>} />

            {/* Ruta para la sección de habilidades */}
            <Route path="/habilidades" element={<LanguageList />} />

            {/* Ruta para la sección de proyectos */}
            <Route path="/proyectos" element={<Proyectos />} />

            {/* Ruta para la sección de contacto */}
            <Route path="/contact" element={<Contacto />} />
          </Routes>
        </main>

        {/* Pie de página del sitio */}
        <footer className="footer">
          <p>&copy; 2024 Tebidev. Todos los derechos reservados.</p>
        </footer>
      </div>
    </Router>
  );
};

// Exportación del componente principal
export default LayoutTebidev;
