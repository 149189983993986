import React, { useState, useEffect } from 'react';
import './LanguageList.css';
import languageService from '../../../../services/languageService'; // Ajusta la ruta si es necesario

const LanguageList = () => {
  // Estados para lenguajes, bases de datos, frameworks y herramientas
  const [languages, setLanguages] = useState([]);
  const [databases, setDatabases] = useState([]);
  const [frameworks, setFrameworks] = useState([]);
  const [tools, setTools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect para obtener todos los datos cuando se monta el componente
  useEffect(() => {
    const fetchData = async () => {
      try {
        const languagesData = await languageService.getLanguages();
        const databasesData = await languageService.getDatabases();
        const frameworksData = await languageService.getFrameworks();
        const toolsData = await languageService.getTools();

        setLanguages(languagesData);
        setDatabases(databasesData);
        setFrameworks(frameworksData);
        setTools(toolsData);
      } catch (err) {
        setError('Error al cargar los datos');
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Llama a la función para obtener los datos
  }, []);

  // Muestra un mensaje de carga mientras se obtienen los datos
  if (loading) {
    return <div>Cargando datos...</div>;
  }

  // Muestra el error si ocurrió un problema al obtener los datos
  if (error) {
    return <div>{error}</div>;
  }

  // Renderiza la lista de elementos con secciones separadas para cada categoría
  return (
    <div className="language-list">
      <h2>Lenguajes de Programación</h2>
      <ul>
        {languages.map((language) => (
          <li key={language.id}>
            <h3>{language.descripcion}</h3>
            <img src={language.url_imagen} alt={language.descripcion} width="100" />
          </li>
        ))}
      </ul>

      <h2>Bases de Datos</h2>
      <ul>
        {databases.map((database) => (
          <li key={database.id}>
            <h3>{database.nombre}</h3>
            <img src={database.url_imagen} alt={database.nombre} width="100" />
          </li>
        ))}
      </ul>

      <h2>Frameworks y Librerías</h2>
      <ul>
        {frameworks.map((framework) => (
          <li key={framework.id}>
            <h3>{framework.nombre}</h3>
            <img src={framework.url_imagen} alt={framework.nombre} width="100" />
          </li>
        ))}
      </ul>

      <h2>Herramientas</h2>
      <ul>
        {tools.map((tool) => (
          <li key={tool.id}>
            <h3>{tool.nombre}</h3>
            <img src={tool.url_imagen} alt={tool.nombre} width="100" />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageList;
